import React from 'react'
import api from '../../api/api'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../../components/ui/card'
import { Label } from '../../components/ui/label'
import { Input } from '../../components/ui/input'
import { Button } from '../../components/ui/button'
import { toast } from '../../components/ui/use-toast'

const ResetPassword = () => {
  const { token } = useParams()
  const navigate = useNavigate()
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()

  const onSubmit = async (data) => {
    await api(
      {
        method: 'POST',
        url: `/auth/reset-password/${token}`,
        data: data,
      },
      (response) => {
        if (response.result !== 'success') {
          return toast({
            title: 'Error',
            description: response.message || 'An error occurred',
            variant: 'destructive',
          })
        }

        toast({
          title: 'Success',
          description: response.message,
        })

        navigate('/login')
      },
    )
  }

  return (
    <form
      className="flex items-center justify-center h-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col items-center justify-center gap-8 w-fit">
        <img src="/images/logo.svg" alt="logo" className="h-10 w-auto" />
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <CardTitle className="text-2xl">Reset Password</CardTitle>
            <CardDescription>
              Enter your information to reset your password
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <div className="flex items-center">
                  <Label htmlFor="password">New Password</Label>
                </div>
                <Input
                  id="newPassword"
                  type="password"
                  placeholder="supersecret123"
                  required
                  {...register('newPassword', {
                    required: 'Password is required',
                    minLength: {
                      value: 8,
                      message: 'Password must be at least 8 characters long',
                    },
                  })}
                />
                {errors.newPassword && (
                  <span className="text-xs text-red-600">
                    {errors.newPassword.message || 'This field is required'}
                  </span>
                )}
              </div>
              <div className="grid gap-2">
                <div className="flex items-center">
                  <Label htmlFor="password">New Password</Label>
                </div>
                <Input
                  id="newPassword2"
                  type="password"
                  placeholder="supersecret123"
                  required
                  {...register('newPassword2', {
                    required: 'Password is required',
                    minLength: {
                      value: 8,
                      message: 'Password must be at least 8 characters long',
                    },
                    validate: (val) => {
                      if (watch('newPassword') != val) {
                        return 'Passwords do not match'
                      }
                    },
                  })}
                />
                {errors.newPassword2 && (
                  <span className="text-xs text-red-600">
                    {errors.newPassword2.message || 'This field is required'}
                  </span>
                )}
              </div>
              <Button type="submit" className="w-full" loading={isSubmitting}>
                Reset Password
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </form>
  )
}

export default ResetPassword
