import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../components/ui/alert-dialog'
import { Button } from '../../components/ui/button'
import React, { useEffect, useState } from 'react'
import { InfoIcon, RotateCwIcon } from 'lucide-react'
import { toast } from '../../components/ui/use-toast'
import api from '../../api/api'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components/ui/form'
import { Input } from '../../components/ui/input'
import { useForm } from 'react-hook-form'
import { Alert, AlertDescription, AlertTitle } from '../../components/ui/alert'

const AutoTopUp = () => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [autopayData, setAutopayData] = useState()

  const form = useForm({
    values: autopayData,
  })
  const fields = form.watch()

  useEffect(() => {
    api(
      {
        method: 'GET',
        url: '/billing/stripe/autopay',
      },
      (data) => {
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setAutopayData(data)
      },
    )
  }, [open])

  const saveSettings = () => {
    api(
      {
        method: 'POST',
        url: '/billing/stripe/autopay/configure',
        data: fields,
      },
      (data) => {
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        toast({
          title: 'Success',
          description: 'Auto top up settings saved',
        })
        setOpen(false)
      },
    )
  }

  const disableAutopay = () => {
    api(
      {
        method: 'POST',
        url: '/billing/stripe/autopay/disable',
      },
      (data) => {
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        toast({
          title: 'Success',
          description: 'Auto top up disabled',
        })
        setOpen(false)
      },
    )
  }

  return (
    <>
      <Button
        variant="default"
        className="px-4 rounded-full"
        onClick={() => setOpen(true)}
      >
        <RotateCwIcon className="w-5 mr-2" />
        AUTO RELOAD
      </Button>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <div className="modal">
          <div className="modal-box">
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  Configure Automatic Balance Top Up
                </AlertDialogTitle>
                <AlertDialogDescription>
                  Auto top up will charge your default payment method when your
                  balance falls below the specified threshold. Maximum of one
                  charge per hour.
                </AlertDialogDescription>
                <Form {...form}>
                  <form>
                    <FormField
                      control={form.control}
                      name="autopay_amount"
                      render={({ field }) => (
                        <FormItem className="mb-2">
                          <FormLabel>Autopay Amount</FormLabel>
                          <FormControl>
                            <div className="relative">
                              <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                                $
                              </span>
                              <Input
                                {...field}
                                placeholder="20"
                                className="pl-8"
                              />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="autopay_threshold"
                      render={({ field }) => (
                        <FormItem className="mb-2">
                          <FormLabel>Autopay Threshold</FormLabel>
                          <FormControl>
                            <div className="relative">
                              <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                                $
                              </span>
                              <Input
                                {...field}
                                placeholder="20"
                                className="pl-8"
                              />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </form>
                </Form>
                {fields.autopay_amount && fields.autopay_threshold && (
                  <Alert>
                    <InfoIcon className="w-4 h-4" />
                    <AlertDescription>
                      If your balance drops below $
                      {Number(fields.autopay_threshold).toFixed(2)}, your
                      payment method will be charged $
                      {Number(fields.autopay_amount).toFixed(2)}.
                    </AlertDescription>
                  </Alert>
                )}
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel className="w-20">Close</AlertDialogCancel>
                <AlertDialogAction asChild>
                  <Button variant="primary" onClick={disableAutopay}>
                    Disable
                  </Button>
                </AlertDialogAction>
                <AlertDialogAction asChild>
                  <Button onClick={saveSettings} loading={loading}>
                    Save
                  </Button>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </div>
        </div>
      </AlertDialog>
    </>
  )
}

export default AutoTopUp
