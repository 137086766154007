import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import api from './api/api'

import './styles/globals.css'

import Home from './pages/Home/Home'
import Login from './pages/Login/Login'
import Register from './pages/Register/Register'
import OrderList from './pages/OrderList/OrderList'
import OrderConfirm from './pages/OrderConfirm/OrderConfirm'
import Settings from './pages/Settings/Settings'
import Billing from './pages/Billing/Billing'
import ServiceList from './pages/ServiceList/ServiceList'
import ServiceView from './pages/ServiceView/ServiceView'
import BillingInvoice from './pages/BillingInvoice/BillingInvoice'
import Support from './pages/Support/Support'
import SupportTicket from './pages/SupportTicket/SupportTicket'
import SupportTicketCreate from './pages/SupportTicketCreate/SupportTicketCreate'
import { Toaster } from './components/ui/toaster'

import { Outlet } from 'react-router-dom'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import Logout from './pages/Logout/Logout'
import { AuthProvider } from './hooks/useAuth'

function PublicRoute({ children }) {
  const [isAuthed, setIsAuthed] = useState(null)
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  // check if user is authenticated on every route change
  useEffect(() => {
    if (loading) return
    setLoading(true)
    api(
      {
        method: 'GET',
        url: '/auth/check',
      },
      (response) => {
        if (response.result === 'success') {
          setIsAuthed(true)
        } else {
          setIsAuthed(false)
        }
        setLoading(false)
      },
      true,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  // on initial hard load
  if (isAuthed === null) {
    return null
  }

  return isAuthed ? <Navigate to="/home" replace /> : children
}

function PrivateRoute({ children }) {
  const [isAuthed, setIsAuthed] = useState(null)
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  // check if user is authenticated on every route change
  useEffect(() => {
    if (loading) return
    setLoading(true)
    api(
      {
        method: 'GET',
        url: '/auth/check',
      },
      (response) => {
        if (response.result === 'success') {
          setIsAuthed(true)
        } else {
          setIsAuthed(false)
        }
        setLoading(false)
      },
      true,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  // on initial hard load
  if (isAuthed === null) {
    return null
  }

  return isAuthed ? children : <Navigate to="/login" replace />
}

function RedirectToHome() {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/home')
  }, [navigate])

  return null
}

const router = createBrowserRouter([
  {
    path: '/login',
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    path: '/logout',
    element: <Logout />,
  },
  {
    path: '/register',
    element: (
      <PublicRoute>
        <Register />
      </PublicRoute>
    ),
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/reset-password/:token',
    element: <ResetPassword />,
  },
  {
    path: '/order',
    element: <OrderList />,
  },
  {
    path: '/order/:groupId',
    element: <OrderConfirm />,
  },
  {
    path: '/support/ticketCreate',
    element: <SupportTicketCreate />,
  },
  {
    path: '/support/ticket/:id',
    element: <SupportTicket />,
  },
  {
    path: '/',
    element: (
      <PrivateRoute>
        <Outlet />
      </PrivateRoute>
    ),
    children: [
      {
        path: '/',
        element: <RedirectToHome />,
      },
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: '/services',
        element: <ServiceList />,
      },
      {
        path: '/service/:id',
        element: <ServiceView />,
      },
      {
        path: '/billing',
        element: <Billing />,
      },
      {
        path: '/billing/invoice/:id',
        element: <BillingInvoice />,
      },
      {
        path: '/settings',
        element: <Settings />,
      },
      {
        path: '/support',
        element: <Support />,
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Toaster />
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>,
)
