import { cn } from '../../lib/utils'
import { Loader2 } from 'lucide-react'

const Loader = ({ className }) => {
  return (
    <div className={cn('flex justify-center w-full', className)}>
      <Loader2 className={'my-28 h-16 w-16 text-primary/60 animate-spin'} />
    </div>
  )
}

export default Loader
