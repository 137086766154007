import React, { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../components/ui/card'

const ProductBilling = ({ options, setOptions, product }) => {
  return (
    <div className="flex flex-row gap-4">
      <BillingScheduleItem
        title="Hourly"
        value="hourly"
        unit="hour"
        price={product?.pricing.hourly}
        options={options}
        setOptions={setOptions}
      />
      <BillingScheduleItem
        title="Monthly"
        value="monthly"
        unit="month"
        price={product?.pricing.monthly}
        options={options}
        setOptions={setOptions}
      />
      <BillingScheduleItem
        title="6 Months"
        value="semiannually"
        unit="6 months"
        price={product?.pricing.semiannually}
        options={options}
        setOptions={setOptions}
      />
    </div>
  )
}

const BillingScheduleItem = ({
  title,
  price,
  options,
  setOptions,
  value,
  unit,
}) => {
  let active = options.billingcycle === value

  const setActive = () => {
    if (!price) return
    setOptions({ ...options, billingcycle: value })
  }

  return (
    <Card className={'w-64' + (active ? ' bg-muted' : '')} onClick={setActive}>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardContent>
        {price && (
          <h2>
            ${price?.toFixed(2)} / {unit}
          </h2>
        )}
        {!price && <h2>Out of stock</h2>}
      </CardContent>
    </Card>
  )
}

export default ProductBilling
