import React, { useEffect, useState } from 'react'
import nvidia from '../../assets/nvidia.png'
import { getGPU } from '../../lib/contentful'
import { Badge } from '../../components/ui/badge'

const GroupSpecs = ({ meta }) => {
  const [gpuContent, setGPUContent] = useState({})

  useEffect(() => {
    if (!meta?.gpu_name) return
    getGPU(meta.gpu_name).then((data) => {
      setGPUContent(data.fields)
    })
  }, [meta])

  return (
    <div className="grid grid-cols-2">
      <div className="">
        <div className="text-2xl font-semibold flex flex-row mb-4 items-center">
          <img src={nvidia} className="h-6 mr-2" alt="Nvidia" />
          {meta?.gpu_name} GPU Servers
        </div>

        <div className="ml-1 space-y-1 mb-4 text-gray-500 text-sm">
          <div>
            VRAM:{' '}
            <span className="font-semibold">
              {meta?.gpu_vram}GB {meta?.gpu_vram_type}
            </span>
          </div>
          <div>
            CPU:{' '}
            <span className="font-semibold">
              {meta?.cpu_name} @ {meta?.cpu_clock}GHz
            </span>
          </div>
          <div>
            SSD: <span className="font-semibold">{meta?.disk_type}</span>
          </div>
          <div>
            RAM: <span className="font-semibold">{meta?.ram_type}</span>
          </div>
          <div>
            Uplink: <span className="font-semibold">10Gbps</span>
          </div>
          <div>
            Location:{' '}
            <span className="font-semibold">USA-Central (Chicago Metro)</span>
          </div>
        </div>
      </div>

      <div className="text-sm">
        <Badge variant="secondary" className="mr-2">
          {gpuContent.cudaCores} CUDA Cores
        </Badge>
        <Badge variant="secondary">{gpuContent.tensorCores} Tensor Cores</Badge>
        <p className="mt-4">{gpuContent.description}</p>
      </div>
    </div>
  )
}

export default GroupSpecs
