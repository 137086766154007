import DashboardLayout from '../../components/DashboardLayout'
import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { Link } from 'react-router-dom'
import ViewTable from '../../components/ViewTable'
import { Button } from '../../components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../../components/ui/card'
import { toast } from '../../components/ui/use-toast'

const Support = () => {
  return (
    <DashboardLayout
      page={'Support'}
      crumbs={[
        { text: 'Dashboard', href: '/home' },
        { text: 'Support', href: '/support' },
      ]}
      content={
        <Card>
          <CardHeader>
            <div className="flex">
              <div className="grow">
                <CardTitle>Support</CardTitle>
                <CardDescription className="mt-2">
                  Manage your support tickets
                </CardDescription>
              </div>
              <div className="flex-none content-center">
                <Link
                  to="/support/ticketCreate"
                  className="btn btn-primary inline-flex items-center"
                >
                  <Button>Open Ticket</Button>
                </Link>
              </div>
            </div>
          </CardHeader>
          <CardContent className="pt-6">
            <TicketTable />
          </CardContent>
        </Card>
      }
    />
  )
}

const TicketTable = () => {
  const [tickets, setTickets] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    api(
      {
        method: 'GET',
        url: '/support/tickets',
      },
      (data) => {
        setLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setTickets(data.tickets)
      },
    )
  }, [])

  const columns = [
    {
      accessorKey: 'id',
      header: 'Ticket ID',
    },
    {
      accessorKey: 'date',
      header: 'Date',
    },
    {
      accessorKey: 'status',
      header: 'Status',
    },
    {
      accessorKey: 'subject',
      header: 'Subject',
    },
    {
      accessorKey: 'action',
      header: '',
      cell: ({ row }) => (
        <Link
          to={`/support/ticket/${row.original.id}`}
          className="btn btn-primary"
        >
          <Button>View Details</Button>
        </Link>
      ),
    },
  ]

  return (
    <ViewTable
      columns={columns}
      data={tickets}
      loading={loading}
      pageSize={5}
    />
  )
}

export default Support
