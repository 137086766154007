import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { Button } from '../../components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose,
} from '../../components/ui/dialog'
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from '../../components/ui/table'
import { Badge } from '../../components/ui/badge'
import { toast } from '../../components/ui/use-toast'
import { Icons } from '../../components/ui/icon'
import { Link } from 'react-router-dom'

const InvoicesButton = ({ service }) => {
  const [invoices, setInvoices] = useState([])
  const [loading, setLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!isOpen) return
    setLoading(true)
    setInvoices([])
    api(
      {
        method: 'GET',
        url: `/services/${service.id}/invoices`,
      },
      (data) => {
        setLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setInvoices(data.invoices)
      },
    )
  }, [isOpen])

  return (
    <>
      <Button variant="outline" onClick={() => setIsOpen(true)}>
        Invoices
      </Button>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="sm:max-w-[800px]">
          <DialogHeader>
            <DialogTitle>Invoices</DialogTitle>
            <DialogDescription>
              View invoices related to this service. Note that some invoices may
              include charges from multiple services, so the total amount may
              not match the service&apos;s billing cycle.
            </DialogDescription>
          </DialogHeader>
          <div className="overflow-auto">
            {loading && (
              <div className="flex items-center justify-center h-32">
                <Icons.spinner className="w-8 h-8 animate-spin" />
              </div>
            )}
            {!loading && (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Invoice ID</TableHead>
                    <TableHead>Amount</TableHead>
                    <TableHead>Date</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {invoices.map((invoice) => (
                    <TableRow key={invoice.id}>
                      <TableCell>{invoice.id}</TableCell>
                      <TableCell>${invoice.subtotal}</TableCell>
                      <TableCell>{invoice.date}</TableCell>
                      <TableCell>
                        <Badge
                          variant={
                            invoice.status === 'Paid'
                              ? 'green'
                              : invoice.status === 'Unpaid'
                                ? 'red'
                                : 'red'
                          }
                        >
                          {invoice.status}
                        </Badge>
                      </TableCell>
                      <TableCell>
                        <Link to={`/billing/invoice/${invoice.id}`}>
                          <Button variant="outline" size="sm">
                            View Details
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
          <DialogFooter>
            <div>
              <Button onClick={() => setIsOpen(false)} variant="outline">
                Close
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default InvoicesButton
