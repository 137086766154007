import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { Link } from 'react-router-dom'
import ViewTable from '../../components/ViewTable'
import { Button } from '../../components/ui/button'
import {
  Card,
  CardTitle,
  CardContent,
  CardHeader,
} from '../../components/ui/card'
import { Icons } from '../../components/ui/icon'
import { toast } from '../../components/ui/use-toast'

const Invoices = () => {
  const [loading, setLoading] = useState(false)
  const [invoices, setInvoices] = useState([])

  useEffect(() => {
    if (loading) return
    setLoading(true)
    api(
      {
        method: 'GET',
        url: '/billing/invoices',
      },
      (data) => {
        setLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setInvoices(data.invoices)
      },
    )
  }, [])

  const columns = [
    {
      accessorKey: 'id',
      header: 'Invoice ID',
    },
    {
      accessorFn: (row) => `$${row.subtotal}`,
      header: 'Amount',
    },
    {
      accessorKey: 'date',
      header: 'Date',
    },
    {
      accessorKey: 'status',
      header: 'Status',
    },
    {
      accessorKey: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <Link to={`/billing/invoice/${row.original.id}`}>
          <Button>View Details</Button>
        </Link>
      ),
    },
  ]

  return (
    <Card>
      <CardHeader>
        <CardTitle>Invoices</CardTitle>
      </CardHeader>
      <CardContent>
        {loading ? (
          <div className="flex items-center justify-center h-40">
            <Icons.spinner className="h-9 w-9 animate-spin" />
          </div>
        ) : (
          <ViewTable columns={columns} data={invoices} loading={false} />
        )}
      </CardContent>
    </Card>
  )
}

export default Invoices
