import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { Link } from 'react-router-dom'
import ViewTable from '../../components/ViewTable'
import moment from 'moment'
import { Button } from '../../components/ui/button'
import { Badge } from '../../components/ui/badge'
import {
  Card,
  CardTitle,
  CardContent,
  CardHeader,
} from '../../components/ui/card'
import { Icons } from '../../components/ui/icon'
import { toast } from '../../components/ui/use-toast'

const NUMBER_INVOICES = 3

const UnpaidInvoices = () => {
  const [loading, setLoading] = useState(false)
  const [invoices, setInvoices] = useState([])

  useEffect(() => {
    if (loading) return
    setLoading(true)
    api(
      {
        method: 'GET',
        url: '/billing/invoices',
      },
      (data) => {
        setLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        // get unpaid invoices
        const unpaidInvoices = data.invoices.filter(
          (invoice) => invoice.status === 'Unpaid',
        )
        setInvoices(unpaidInvoices.slice(0, NUMBER_INVOICES))
      },
    )
  }, [])

  const columns = [
    {
      header: 'Amount',
      accessorFn: (row) => `$${row.subtotal}`,
      cell: ({ row }) => (
        <Link to={`/billing/invoice/${row.original.id}`}>
          <Button variant="link">${row.original.subtotal}</Button>
        </Link>
      ),
    },
    {
      header: 'Date',
      accessorFn: (row) => moment(row.date).format('MM/DD/YYYY'),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }) => (
        <Badge variant={row.original.status === 'Unpaid' ? 'outline' : ''}>
          {row.original.status}
        </Badge>
      ),
    },
  ]

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Unpaid Invoices</CardTitle>
      </CardHeader>
      <CardContent>
        {loading ? (
          <div className="flex items-center justify-center h-40">
            <Icons.spinner className="h-9 w-9 animate-spin" />
          </div>
        ) : (
          <ViewTable
            columns={columns}
            data={invoices}
            loading={false}
            disablePagination={true}
          />
        )}
      </CardContent>
    </Card>
  )
}

export default UnpaidInvoices
