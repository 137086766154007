import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import api from '../../api/api'
import ViewTable from '../../components/ViewTable'
import { Button } from '../../components/ui/button'
import { Badge } from '../../components/ui/badge'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../components/ui/card'
import { toast } from '../../components/ui/use-toast'

const NUMBER_TICKETS = 3

const RecentTickets = () => {
  return (
    <Card className="w-full">
      <CardHeader>
        <div className="flex grow">
          <div className="grow">
            <CardTitle>Recent Tickets</CardTitle>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <TicketTable />
      </CardContent>
    </Card>
  )
}

const TicketTable = () => {
  const [tickets, setTickets] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    api(
      {
        method: 'GET',
        url: '/support/tickets',
      },
      (data) => {
        setLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setTickets(data.tickets.slice(0, NUMBER_TICKETS))
      },
    )
  }, [])

  const columns = [
    {
      accessorKey: 'subject',
      header: 'Subject',
      cell: ({ row }) => (
        <Link to={`/support/ticket/${row.original.id}`}>
          <Button variant="link">{row.original.subject}</Button>
        </Link>
      ),
    },
    {
      header: 'Date',
      accessorFn: (row) => moment(row.date).format('MM/DD/YYYY'),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }) => (
        <Badge variant={row.original.status === 'Open' ? 'outline' : ''}>
          {row.original.status}
        </Badge>
      ),
    },
  ]

  return (
    <ViewTable
      columns={columns}
      data={tickets}
      loading={loading}
      disablePagination={true}
    />
  )
}

export default RecentTickets
