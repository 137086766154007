import DashboardLayout from '../../components/DashboardLayout'
import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components/ui/form'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../components/ui/card'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/ui/button'
import { Textarea } from '../../components/ui/textarea'
import { Input } from '../../components/ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select'
import { toast } from '../../components/ui/use-toast'
import { useAuth } from '../../hooks/useAuth'

const SupportTicketCreate = () => {
  const [departments, setDepartments] = useState()
  const [services, setServices] = useState()
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const { isAuthed } = useAuth()

  const formSchema = z.object({
    department: z.string().min(1, { message: 'Department is required.' }),
    subject: z.string().min(1, { message: 'Subject is required.' }),
    body: z.string().min(1, { message: 'Body is required.' }),
    name: z.string().optional(),
    email: z.string().email({ message: 'Invalid email address.' }).optional(),
    service: z.string().optional(),
  })

  const form = useForm({
    resolver: zodResolver(formSchema),
  })

  const getDepartments = () => {
    api(
      {
        method: 'GET',
        url: '/support/departments',
      },
      (data) => {
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description:
              data?.message || 'An error occurred while fetching departments.',
            variant: 'destructive',
          })
        }
        setDepartments(data.departments)
      },
    )
  }

  const getServices = () => {
    if (!isAuthed) return
    api(
      {
        method: 'GET',
        url: '/services',
      },
      (data) => {
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description:
              data?.message || 'An error occurred while fetching services.',
            variant: 'destructive',
          })
        }
        setServices(data.services)
      },
    )
  }

  const onLoad = async () => {
    setLoading(true)
    await Promise.all([getDepartments(), getServices()])
    setLoading(false)
  }

  useEffect(() => {
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthed])

  const onSubmit = (data) => {
    if (submitting) return
    setSubmitting(true)
    api(
      {
        method: 'POST',
        url: '/support/tickets',
        data: {
          departmentId: data.department,
          serviceId: data.service,
          subject: data.subject,
          email: data.email,
          name: data.name,
          message: data.body,
        },
      },
      (data) => {
        setSubmitting(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description:
              data?.message || 'An error occurred while creating ticket.',
            variant: 'destructive',
          })
        }
        toast({
          title: 'Success',
          description: 'Ticket created successfully.',
        })
        form.reset()
        navigate(`/support/ticket/${data.ticketId}`)
      },
    )
  }

  return (
    <DashboardLayout
      page={'Support'}
      crumbs={[
        { text: 'Dashboard', href: '/home' },
        { text: 'Support', href: '/support' },
        { text: 'Create Ticket', href: '/support/ticket/create' },
      ]}
      content={
        <Card>
          <CardHeader>
            <CardTitle>Create Ticket</CardTitle>
          </CardHeader>
          <CardContent>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <FormField
                  control={form.control}
                  name="department"
                  render={({ field }) => (
                    <FormItem className="mb-2">
                      <FormLabel>Department</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select a department" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectGroup>
                            {departments &&
                              departments.map((d) => (
                                <SelectItem key={d.id} value={d.id.toString()}>
                                  {d.name}
                                </SelectItem>
                              ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {isAuthed && (
                  <FormField
                    control={form.control}
                    name="service"
                    render={({ field }) => (
                      <FormItem className="mb-2">
                        <FormLabel>Service</FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select a service" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectGroup>
                              {services &&
                                services.map((s) => (
                                  <SelectItem
                                    key={s.id}
                                    value={s.id.toString()}
                                  >
                                    {s.domain} {s.name}
                                  </SelectItem>
                                ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}

                {!isAuthed && (
                  <>
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem className="mb-2">
                          <FormLabel>Name</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem className="mb-2">
                          <FormLabel>Email</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </>
                )}

                <FormField
                  control={form.control}
                  name="subject"
                  render={({ field }) => (
                    <FormItem className="mb-2">
                      <FormLabel>Subject</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="body"
                  render={({ field }) => (
                    <FormItem className="mb-2">
                      <FormLabel>Ticket Body</FormLabel>
                      <FormControl>
                        <Textarea rows={3} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button
                  loading={submitting}
                  type="submit"
                  disabled={loading}
                  className="mt-2"
                >
                  Submit Ticket
                </Button>
              </form>
            </Form>
          </CardContent>
        </Card>
      }
    />
  )
}

export default SupportTicketCreate
