import { useEffect, useState } from 'react'
import api from '../../api/api'
import { LineChart } from '@mui/x-charts'
import { Icons } from '../../components/ui/icon'
import {
  Card,
  CardTitle,
  CardContent,
  CardHeader,
} from '../../components/ui/card'
import moment from 'moment'

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select'
import { toast } from '../../components/ui/use-toast'
import prettyBytes from 'pretty-bytes'

const ServiceCharts = ({ service }) => {
  const [loading, setLoading] = useState(true)
  const [stats, setStats] = useState()
  const [timeframe, setTimeframe] = useState('hour')

  useEffect(() => {
    if (!service.id) return
    api(
      {
        method: 'GET',
        url: `/server/${service.id}/stats`,
        params: { timeframe },
      },
      (data) => {
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }

        setStats(
          data.stats.map((dp) => {
            dp.date = moment(dp.date)
            dp.cpu = dp.cpu || 0
            dp.diskread = dp.diskread || 0
            dp.diskwrite = dp.diskwrite || 0
            dp.netin = dp.netin || 0
            dp.netout = dp.netout || 0
            return dp
          }),
        )
      },
    ).finally(() => setLoading(false))
  }, [timeframe, service])

  const timeAxis = {
    dataKey: 'date',
    scaleType: 'time',
    tickNumber: 6,
    valueFormatter: (date) => {
      switch (timeframe) {
        case 'hour':
          return moment(date).format('HH:mm')
        case 'day':
          return moment(date).format('HH:mm')
        case 'week':
          return moment(date).format('MM/DD')
        case 'month':
          return moment(date).format('MM/DD')
      }
    },
  }

  return (
    <>
      <TimeframeSelect setTimeframe={setTimeframe} timeframe={timeframe} />

      <CpuChart stats={stats} timeAxis={timeAxis} loading={loading} />

      <NetworkChart stats={stats} timeAxis={timeAxis} loading={loading} />
    </>
  )
}

const CpuChart = ({ stats, timeAxis, loading }) => {
  return (
    <Card className="mt-4">
      <CardHeader>
        <CardTitle>CPU Usage</CardTitle>
      </CardHeader>
      <CardContent>
        <>
          {loading && (
            <div className="flex items-center justify-center h-32">
              <Icons.spinner className="w-8 h-8 animate-spin" />
            </div>
          )}
          {!loading && (
            <LineChart
              height={300}
              dataset={stats || []}
              xAxis={[timeAxis]}
              series={[
                {
                  dataKey: 'cpu',
                  showMark: false,
                  valueFormatter: (v) => v?.toFixed(2) + '%',
                },
              ]}
            />
          )}
        </>
      </CardContent>
    </Card>
  )
}

const NetworkChart = ({ stats, timeAxis, loading }) => {
  return (
    <Card className="mt-4">
      <CardHeader>
        <CardTitle>Network I/O</CardTitle>
      </CardHeader>
      <CardContent>
        <>
          {loading && (
            <div className="flex items-center justify-center h-32">
              <Icons.spinner className="w-8 h-8 animate-spin" />
            </div>
          )}
          {!loading && (
            <LineChart
              height={300}
              dataset={stats || []}
              xAxis={[timeAxis]}
              yAxis={[
                {
                  valueFormatter: (v) => prettyBytes(v, { bits: true }),
                },
              ]}
              series={[
                {
                  dataKey: 'netin',
                  showMark: false,
                  valueFormatter: (v) => prettyBytes(v, { bits: true }),
                },
                {
                  dataKey: 'netout',
                  showMark: false,
                  valueFormatter: (v) => prettyBytes(v, { bits: true }),
                },
              ]}
            />
          )}
        </>
      </CardContent>
    </Card>
  )
}

const TimeframeSelect = ({ setTimeframe, timeframe }) => {
  return (
    <div className="flex items-center justify-end mt-4">
      <Select value={timeframe} onValueChange={setTimeframe}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Select Timeframe" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="hour">Hour</SelectItem>
            <SelectItem value="day">Day</SelectItem>
            <SelectItem value="week">Week</SelectItem>
            <SelectItem value="month">Month</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  )
}

export default ServiceCharts
