import React from 'react'
import api from '../../api/api'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../../components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../../components/ui/card'
import { Input } from '../../components/ui/input'
import { Label } from '../../components/ui/label'
import { toast } from '../../components/ui/use-toast'
import { useAuth } from '../../hooks/useAuth'

const Login = () => {
  const navigate = useNavigate()
  const form = useForm()
  const { updateAuth } = useAuth()

  const onSubmit = async (data) => {
    await api(
      {
        method: 'POST',
        url: '/auth/login',
        data: data,
      },
      (response) => {
        if (response.result !== 'success') {
          return toast({
            title: 'Error',
            description: response.message || 'An error occurred',
            variant: 'destructive',
          })
        }

        toast({
          title: 'Success',
          description: response.message,
        })

        updateAuth()

        navigate('/home')
      },
    )
  }

  return (
    <div className="flex flex-col items-center justify-center h-full w-full">
      <div className="flex flex-col items-center justify-center gap-8 w-fit">
        <img src="/images/logo.svg" alt="logo" className="h-10 w-auto" />
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <CardTitle className="text-2xl">Login</CardTitle>
            <CardDescription>
              Enter your email below to login to your account
            </CardDescription>
          </CardHeader>
          <CardContent>
            <LoginForm onSubmit={onSubmit} form={form} />
            <div className="mt-4 text-center text-sm">
              Don&apos;t have an account?{' '}
              <Link to="/register" className="underline">
                Sign up
              </Link>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export const LoginForm = ({
  onSubmit,
  form: {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  },
}) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="grid gap-4">
      <div className="grid gap-2">
        <Label htmlFor="email">Email</Label>
        <Input
          id="email"
          type="email"
          {...register('email', { required: true })}
          required
        />
        {errors.email && (
          <span className="text-xs text-red-600">
            {errors.email.message || 'This field is required'}
          </span>
        )}
      </div>
      <div className="grid gap-2">
        <div className="flex items-center">
          <Label htmlFor="password">Password</Label>
          <Link
            to={'/forgot-password'}
            className="ml-auto inline-block text-sm underline"
          >
            Forgot your password?
          </Link>
        </div>
        <Input
          id="password"
          type="password"
          required
          {...register('password', { required: true })}
        />
        {errors.password && (
          <span className="text-xs text-red-600">
            {errors.password.message || 'This field is required'}
          </span>
        )}
      </div>
      <Button type="submit" className="w-full" loading={isSubmitting}>
        Login
      </Button>
    </form>
  )
}

export default Login
