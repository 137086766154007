import React from 'react'
import api from '../../api/api'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../../components/ui/card'
import { Label } from '../../components/ui/label'
import { Input } from '../../components/ui/input'
import { Button } from '../../components/ui/button'
import { toast } from '../../components/ui/use-toast'
import { useAuth } from '../../hooks/useAuth'

const Register = () => {
  const navigate = useNavigate()
  const form = useForm()
  const { updateAuth } = useAuth()

  const onSubmit = async (data) => {
    await api(
      {
        method: 'POST',
        url: '/auth/register',
        data: data,
      },
      (response) => {
        if (response.result !== 'success') {
          return toast({
            title: 'Error',
            description: response.message || 'An error occurred',
            variant: 'destructive',
          })
        }

        toast({
          title: 'Success',
          description: response.message,
        })

        updateAuth()

        navigate('/home')
      },
    )
  }

  return (
    <div className="flex flex-col items-center justify-center h-full w-full">
      <div className="flex flex-col items-center justify-center gap-8 w-fit">
        <img src="/images/logo.svg" alt="logo" className="h-10 w-auto" />
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <CardTitle className="text-2xl">Register</CardTitle>
            <CardDescription>
              Enter your information to create an account
            </CardDescription>
          </CardHeader>
          <CardContent>
            <RegisterForm onSubmit={onSubmit} form={form} />
            <div className="mt-4 text-center text-sm">
              Already have an account?{' '}
              <Link to="/login" className="underline">
                Sign in
              </Link>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export const RegisterForm = ({
  onSubmit,
  form: {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  },
}) => {
  return (
    <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-2">
        <Label htmlFor="email">Email</Label>
        <Input
          id="email"
          type="email"
          {...register('email', { required: true })}
          required
        />
        {errors.email && (
          <span className="text-xs text-red-600">
            {errors.email.message || 'This field is required'}
          </span>
        )}
      </div>
      <div className="grid gap-2">
        <div className="flex items-center">
          <Label htmlFor="password">Password</Label>
        </div>
        <Input
          id="password"
          type="password"
          required
          {...register('password', {
            required: 'Password is required',
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters long',
            },
          })}
        />
        {errors.password && (
          <span className="text-xs text-red-600">
            {errors.password.message || 'This field is required'}
          </span>
        )}
      </div>
      <Button type="submit" className="w-full" loading={isSubmitting}>
        Register
      </Button>
    </form>
  )
}

export default Register
