import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { Link } from 'react-router-dom'
import { Button } from '../../components/ui/button'
import { CreditCard } from 'lucide-react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../components/ui/dialog'
import { toast } from '../../components/ui/use-toast'

const CardPaymentButton = ({ invoice, id, fetchInvoice }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [creditCards, setCreditCards] = useState([])

  useEffect(() => {
    api(
      {
        method: 'GET',
        url: '/billing/stripe/cards',
      },
      (data) => {
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setCreditCards(data.payment_methods)
      },
    )
  }, [])

  const capturePayment = () => {
    setLoading(true)
    api(
      {
        method: 'POST',
        url: `/billing/invoices/${id}/capture`,
      },
      (data) => {
        if (data.result !== 'success') {
          setLoading(false)
          setOpen(false)
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        fetchInvoice()
          .then(() => {
            toast({
              title: 'Success',
              description: 'Payment captured',
            })
          })
          .finally(() => {
            setLoading(false)
            setOpen(false)
          })
      },
    )
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="mr-3">
          <CreditCard className="mr-2 h-4 w-4" />
          Pay with Card
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Confirm Payment</DialogTitle>
        </DialogHeader>
        {!creditCards.length ? (
          <>
            <p>No credit cards on file</p>
            <Link to="/billing">
              <button className="btn btn-primary">Add Credit Card</button>
            </Link>
          </>
        ) : (
          <>
            <p>
              Paying with {creditCards[0].card_type} ending in{' '}
              {creditCards[0].card_last_four}
            </p>
            <Link to="/billing">
              <Button>Add / Change Credit Card</Button>
            </Link>
            <Button onClick={capturePayment} loading={loading}>
              PAY ${invoice.total}
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CardPaymentButton
