import React, { useEffect, useRef, useState } from 'react'
import Autoplay from 'embla-carousel-autoplay'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '../../components/ui/carousel'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../components/ui/card'
import { Icons } from '../../components/ui/icon'
import ReactMarkdown from 'react-markdown'
import { listAnnouncements } from '../../lib/contentful'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const Announcements = () => {
  const plugin = useRef(
    Autoplay({
      delay: 5000,
      stopOnInteraction: true,
    }),
  )
  const [announcementsData, setAnnouncementsData] = useState([])

  useEffect(() => {
    listAnnouncements().then((data) => {
      setAnnouncementsData(data)
      console.log(data)
    })
  }, [])

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center">
          <Icons.bell className="w-6 h-6 mr-2" />
          Announcements
        </CardTitle>
      </CardHeader>
      <CardContent className="flex items-center justify-center p-6 w-full">
        <Carousel
          plugins={[plugin.current]}
          className="w-full min-h-24 flex flex-row items-center justify-between"
          onMouseEnter={plugin.current.stop}
          onMouseLeave={plugin.current.play}
        >
          <CarouselPrevious className="relative left-0 transform-none" />
          <CarouselContent className="flex items-center p-4">
            {announcementsData.map((announcement, index) => (
              <CarouselItem
                key={index}
                className="flex flex-col justify-center items-center"
              >
                {documentToReactComponents(announcement.fields.slideHeading)}
                {documentToReactComponents(announcement.fields.slideContent)}
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselNext className="relative right-0 transform-none" />
        </Carousel>
      </CardContent>
    </Card>
  )
}

export default Announcements
