import DashboardLayout from '../../components/DashboardLayout'
import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { useParams } from 'react-router-dom'
import ServerControls from './ServerControls'
import ServiceCharts from './ServiceCharts'
import useInterval from '../../hooks/useInterval'
import { Badge } from '../../components/ui/badge'
import { Icons } from '../../components/ui/icon'
import {
  CardHeader,
  CardTitle,
  CardContent,
  Card,
} from '../../components/ui/card'
import { toast } from '../../components/ui/use-toast'
import { AlertCircle } from 'lucide-react'
import { Alert, AlertDescription, AlertTitle } from '../../components/ui/alert'
import ServiceSpecs from './ServiceSpecs'
import BillingInfo from './BillingInfo'

// TODO: suspension reason {service.suspensionreason && `(${service.suspensionreason})`}

const ServiceView = () => {
  const [loadingService, setLoadingService] = useState(true)
  const [loadingServer, setLoadingServer] = useState(true)
  const [service, setService] = useState()
  const [server, setServer] = useState({})
  const { id } = useParams()

  const fetchService = () => {
    if (service && service.status !== 'Pending') return
    api(
      {
        method: 'GET',
        url: `/services/${id}`,
      },
      (data) => {
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setService(data.service)
      },
    ).finally(() => setLoadingService(false))
  }
  useInterval(fetchService, 2000)
  useEffect(fetchService, [])

  useInterval(() => {
    if (service.status !== 'Active') return
    api(
      {
        method: 'GET',
        url: `/server/${id}/status`,
      },
      (data) => {
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setServer(data.server)
      },
    ).finally(() => setLoadingServer(false))
  }, 2000)

  if (loadingService) {
    return (
      <DashboardLayout
        page={'Services'}
        crumbs={[
          { text: 'Dashboard', href: '/home' },
          { text: 'Services', href: '/services' },
          { text: 'View Service #' + id, href: '/service/' + id },
        ]}
        content={
          <Card>
            <CardHeader>
              <CardTitle className="flex gap-2 items-center">
                <p>Service View - ID {id}</p>
              </CardTitle>
            </CardHeader>
            <CardContent className="pt-6">
              <div className="flex items-center justify-center h-32">
                <Icons.spinner className="w-8 h-8 animate-spin" />
              </div>
            </CardContent>
          </Card>
        }
      />
    )
  }

  return (
    <DashboardLayout
      page={'Services'}
      crumbs={[
        { text: 'Dashboard', href: '/home' },
        { text: 'Services', href: '/services' },
        { text: 'View Service #' + id, href: '/service/' + id },
      ]}
      content={
        <>
          <div className="pb-3">
            <CardTitle className="flex gap-2 items-center">
              <p>Service View - ID {id}</p>
              {service?.status && (
                <Badge variant="outline" className="bg-white">
                  <div
                    className={
                      (service.status === 'Active'
                        ? 'bg-green-500'
                        : 'bg-red-500') + ' h-3 w-3 rounded-full mr-1'
                    }
                  />
                  {service?.status?.toUpperCase()}
                </Badge>
              )}
            </CardTitle>
          </div>
          <div>
            {server.locked && (
              <Alert variant="destructive" className="mb-4">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Locked Server</AlertTitle>
                <AlertDescription>
                  Server is currently locked due to a pending operation.
                  Controls are disabled.
                </AlertDescription>
              </Alert>
            )}
            {(service.status === 'Terminated' ||
              service.status === 'Cancelled') && (
              <Alert variant="destructive" className="mb-4">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Inactive Service</AlertTitle>
                <AlertDescription>
                  This service was cancelled or terminated.
                </AlertDescription>
              </Alert>
            )}

            <div className="grid grid-cols-2 gap-4">
              <ServiceSpecs service={service} />
              <BillingInfo service={service} />
            </div>

            <ServerControls service={service} server={server} />

            {service?.status === 'Active' && (
              <>
                <ServiceCharts service={service} />
              </>
            )}
          </div>
        </>
      }
    />
  )
}

export default ServiceView
