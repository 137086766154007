import DashboardLayout from '../../components/DashboardLayout'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../api/api'
import { Button } from '../../components/ui/button'
import { Textarea } from '../../components/ui/textarea'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../components/ui/form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
  CardHeader,
} from '../../components/ui/card'
import { toast } from '../../components/ui/use-toast'

const Reply = ({ reply }) => {
  let isSent = reply.type === 'Owner'
  return (
    <div className={`flex items-start gap-2.5 ${isSent ? 'justify-end' : ''}`}>
      <div className="flex flex-col w-full max-w-[320px] leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-700">
        <div className="flex items-center space-x-2 rtl:space-x-reverse">
          <span className="text-sm font-semibold text-gray-900 dark:text-white">
            {reply.name.indexOf('N/A') !== -1 ? reply.email : reply.name}
          </span>
          <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
            {reply.date}
          </span>
        </div>
        <p className="text-sm font-normal py-2.5 text-gray-900 dark:text-white">
          {reply.message}
        </p>
      </div>
    </div>
  )
}

const SupportTicket = () => {
  const { id } = useParams()
  const [ticket, setTicket] = useState(null)

  const loadTicket = () => {
    api(
      {
        method: 'GET',
        url: `/support/tickets/${id}`,
      },
      (data) => {
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setTicket(data.ticket)
      },
    )
  }

  useEffect(loadTicket, [id])

  return (
    <DashboardLayout
      page={'Support'}
      crumbs={[
        { text: 'Dashboard', href: '/home' },
        { text: 'Support', href: '/support' },
        { text: 'View Ticket #' + id, href: '/support/ticket/' + id },
      ]}
      content={
        <Card>
          <CardHeader>
            <CardTitle>{ticket?.subject}</CardTitle>
            <CardDescription>
              <div className="flex items-center space-x-4">
                <p>Department: {ticket?.deptname}</p>
                <p>Date: {ticket?.date}</p>
                {ticket?.service && <p>Service: {ticket?.service}</p>}
              </div>
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="p-6 space-y-6 h-full">
              {ticket && (
                <div className="flex flex-col justify-between h-full">
                  <div className="space-y-4">
                    {ticket?.replies.map((reply) => (
                      <Reply reply={reply} key={reply.id} />
                    ))}
                  </div>
                  <ReplyForm ticket={ticket} loadTicket={loadTicket} />
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      }
    />
  )
}

const ReplyForm = ({ ticket, loadTicket }) => {
  const [loading, setLoading] = useState(false)

  const formSchema = z.object({
    reply: z.string().min(1, { message: 'Reply is required.' }),
  })

  const form = useForm({
    resolver: zodResolver(formSchema),
  })

  const submitReply = (values) => {
    if (loading) return
    setLoading(true)
    form.reset({
      reply: '',
    })
    api(
      {
        method: 'POST',
        url: `/support/tickets/${ticket?.id}/reply`,
        data: {
          message: values.reply,
        },
      },
      (data) => {
        setLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        loadTicket()
      },
    )
  }

  return (
    <Form {...form} className="space-y-4 justify-end">
      <form onSubmit={form.handleSubmit(submitReply)}>
        <FormField
          control={form.control}
          name="reply"
          render={({ field }) => (
            <FormItem className="mb-2">
              <FormControl>
                <Textarea
                  className="mt-8 w-full"
                  placeholder="Your ticket reply..."
                  rows={6}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button loading={loading} type="submit" className="w-fit mt-2">
          Submit Reply
        </Button>
      </form>
    </Form>
  )
}

export default SupportTicket
