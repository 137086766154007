import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { Controller } from 'react-hook-form'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select'
import { toast } from '../../components/ui/use-toast'

const OSSelect = ({ name, control }) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    api(
      {
        method: 'GET',
        url: '/server/ostemplates',
      },
      (data) => {
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setOptions(data.osTemplates)
      },
    )
  }, [])

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <Select onValueChange={field.onChange} value={field.value}>
          <SelectTrigger>
            <SelectValue placeholder="Select OS" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {options.map((option) => {
                return (
                  <SelectItem key={option.name} value={option.name}>
                    {`${option.name}`}
                  </SelectItem>
                )
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
      )}
    />
  )
}

export default OSSelect
