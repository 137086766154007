import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { icons } from 'lucide-react'
import LogoFull from '../assets/logo_full.svg'
import { useAuth } from '../hooks/useAuth.js'

const items = [
  {
    name: 'Home',
    path: '/home',
    icon: 'Home',
    auth: true,
  },
  {
    name: 'Order',
    path: '/order',
    icon: 'ShoppingCart',
  },
  {
    name: 'Services',
    path: '/services',
    icon: 'Server',
    auth: true,
  },
  {
    name: 'Support',
    path: '/support',
    icon: 'MessageCircleQuestion',
    auth: true,
  },
  // Direct link to create page for non-authed users
  {
    name: 'Support',
    path: '/support/ticketCreate',
    icon: 'MessageCircleQuestion',
    auth: false,
  },
  {
    name: 'Billing',
    path: '/billing',
    icon: 'CreditCard',
    auth: true,
  },
  {
    name: 'API',
    path: 'https://docs.puregpu.com',
    icon: 'Code',
  },
  {
    name: 'Settings',
    path: '/settings',
    icon: 'Settings',
    auth: true,
  },
  {
    name: 'Logout',
    path: '/logout',
    icon: 'LogOut',
    auth: true,
  },
  {
    name: 'Login',
    path: '/login',
    icon: 'LogIn',
    auth: false,
  },
  {
    name: 'Register',
    path: '/register',
    icon: 'UserPlus',
    auth: false,
  },
]
const SidebarComponent = ({ page }) => {
  const location = useLocation()
  const { isAuthed } = useAuth()

  return (
    <div className="hidden border-r md:block">
      <div className="flex h-full max-h-screen flex-col gap-2">
        <div className="flex h-20 items-center border-b px-4">
          <Link href="/home" className="flex items-center gap-2 font-semibold">
            <img src={LogoFull} alt="PureGPU" className="h-9" />
          </Link>
        </div>
        <div className="flex-1">
          <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
            {items.map((item) => {
              if (typeof item.auth === 'boolean' && isAuthed !== item.auth) {
                return null
              }

              let Icon = icons[item.icon]
              const isActive = location.pathname === item.path
              return (
                <Link
                  to={item.path}
                  key={item.name}
                  className={`flex text-[1rem] my-1 py-3 items-center gap-3 rounded-lg px-3 py-2 transition-all ${
                    isActive
                      ? 'bg-muted text-primary'
                      : 'text-muted-foreground hover:text-primary'
                  }`}
                >
                  <Icon className="h-5 w-5" />
                  {item.name}
                </Link>
              )
            })}
          </nav>
        </div>
      </div>
    </div>
  )
}

//<Home className="h-4 w-4" />

export default SidebarComponent
