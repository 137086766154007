import React, { useEffect } from 'react'
import api from '../../api/api'

const Logout = () => {
  useEffect(() => {
    api(
      {
        method: 'POST',
        url: '/auth/logout',
      },
      () => {
        window.location.href = '/login'
      },
    )
  }, [])

  return <></>
}

export default Logout
