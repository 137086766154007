import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { Link } from 'react-router-dom'
import { Button } from '../../components/ui/button'
import { Input } from '../../components/ui/input'
import { Label } from '../../components/ui/label'
import { Bitcoin, Clipboard, ClipboardCheck, CreditCard } from 'lucide-react'
import useInterval from '../../hooks/useInterval'
import moment from 'moment'
import { Badge } from '../../components/ui/badge'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../components/ui/dialog'
import { toast } from '../../components/ui/use-toast'

const CryptoPaymentButton = ({ invoice, id }) => {
  const [currency, setCurrency] = useState(null)

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="mr-3">
          <Bitcoin className="mr-2 h-4 w-4" />
          Pay with Crypto
        </Button>
      </DialogTrigger>

      {!currency && <CurrencySelector setCurrency={setCurrency} />}
      {currency && (
        <PaymentDisplay currency={currency} invoice={invoice} id={id} />
      )}
    </Dialog>
  )
}

const CurrencySelector = ({ setCurrency }) => {
  const [loading, setLoading] = useState(false)
  const [currencies, setCurrencies] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    setLoading(true)
    api(
      {
        method: 'GET',
        url: '/billing/crypto/currencies',
      },
      (data) => {
        setLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setCurrencies(data.currencies)
      },
    )
  }, [])

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Select cryptocurrency</DialogTitle>
      </DialogHeader>

      <Input
        placeholder="Search for a specific currency..."
        onChange={(e) => setSearch(e.target.value.toLowerCase())}
      />
      <div className="max-h-96 overflow-y-scroll">
        {currencies
          .filter((currency) => {
            return currency.name.toLowerCase().includes(search)
          })
          .map((currency) => (
            <div
              key={currency.id}
              className="flex flex-row mt-1 mb-2 items-center"
              onClick={() => {
                setCurrency(currency)
              }}
            >
              <img
                src={'https://nowpayments.io/' + currency.logo}
                alt={currency.name}
                width="24"
                height="24"
                className="mr-3"
              />
              <div>{currency.name}</div>
            </div>
          ))}
      </div>
    </DialogContent>
  )
}

const PaymentDisplay = ({ currency, invoice, id }) => {
  const [payment, setPayment] = useState({})
  const [status, setStatus] = useState('waiting')
  const [timeLeft, setTimeLeft] = useState()

  useEffect(() => {
    api(
      {
        method: 'POST',
        url: '/billing/crypto/payments',
        data: {
          invoice_id: id,
          currency: currency.code,
        },
      },
      (data) => {
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setPayment(data.payment)
      },
    )
  }, [currency])

  useInterval(() => {
    api(
      {
        method: 'GET',
        url: `/billing/crypto/payments/${payment.id}`,
      },
      (data) => {
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setStatus(data.payment.status)
      },
    )
  }, 5000)

  useInterval(() => {
    setTimeLeft(
      moment
        .utc(moment(payment?.expiration_estimate_date).diff(moment()))
        .format('mm:ss'),
    )
  }, 1000)

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          <div className="flex grow">Paying with</div>
          <div className="flex">
            <img
              src={'https://nowpayments.io/' + currency.logo}
              alt={currency.name}
              width="24"
              height="24"
              className="mr-3"
            />
            <div>
              {currency.name} - {currency.code}
            </div>
          </div>
        </DialogTitle>
      </DialogHeader>

      <div>
        Status: <Badge>{status.toUpperCase()}</Badge>
      </div>

      {['waiting', 'confirming', 'partially_paid'].includes(status) && (
        <div>
          Time Remaining: <span className="font-bold">{timeLeft}</span>
        </div>
      )}

      <div className="grid w-full items-center gap-1.5 mt-2">
        <Label htmlFor="pay_address">Payment Address</Label>
        <CopyInput id="pay_address" value={payment?.pay_address} />
      </div>
      <div className="grid w-full items-center gap-1.5 mt-2">
        <Label htmlFor="pay_amount">Payment Amount</Label>
        <CopyInput id="pay_amount" value={payment?.pay_amount} />
      </div>
      {payment?.payin_extra_id && (
        <div className="grid w-full items-center gap-1.5 mt-2">
          <Label htmlFor="memo">Memo</Label>
          <CopyInput id="memo" value={payment?.payin_extra_id} />
        </div>
      )}
    </DialogContent>
  )
}

const CopyInput = ({ value, id }) => {
  const [copied, setCopied] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value).then(() => {
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    })
  }

  return (
    <div className="flex">
      <Input id={id} value={value} readOnly className="flex-grow" />
      <button
        onClick={copyToClipboard}
        className="ml-2 p-2 border border-gray-300 rounded"
      >
        {copied ? <ClipboardCheck /> : <Clipboard />}
      </button>
    </div>
  )
}

export default CryptoPaymentButton
