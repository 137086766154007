import { useState, useEffect } from 'react'
import api from '../../api/api'
import { Button } from '../../components/ui/button'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select'
import { toast } from '../../components/ui/use-toast'
import { generateRootPassword } from '../../lib/utils'
import CopyBlock from '../../components/ui/copy-block'
import { Input } from '../../components/ui/input'
import { Card, CardContent } from '../../components/ui/card'
import {
  CircleStopIcon,
  ListRestartIcon,
  MonitorIcon,
  PlayIcon,
  PowerOffIcon,
  RefreshCwIcon,
} from 'lucide-react'

const ServerControls = ({ service, server }) => {
  return (
    <Card className="mt-4">
      <CardContent className="pt-6 grid grid-cols-3 gap-4">
        {(service?.status === 'Active' ||
          service?.status === 'Suspended' ||
          service?.status === 'Pending') && (
          <div className="grid gap-4">
            <div className="grid grid-cols-[120px_1fr] items-center gap-2">
              <div className="font-medium">Public IP</div>
              <div>
                <Input value={service.dedicatedip} />
              </div>
            </div>
            <div className="grid grid-cols-[120px_1fr] items-center gap-2">
              <div className="font-medium">Hostname</div>
              <div>{service.domain}</div>
            </div>
          </div>
        )}
        {service?.status === 'Active' && (
          <div className="grid col-span-2 justify-items-end">
            <div className="flex items-center gap-2">
              <ControlButton
                disabled={
                  !server.status || server.status === 'running' || server.locked
                }
                path={`/server/${service.id}/start`}
              >
                <PlayIcon className="w-4 h-4 mr-2" />
                Start
              </ControlButton>
              <ControlButton
                disabled={
                  !server.status || server.status !== 'running' || server.locked
                }
                path={`/server/${service.id}/stop`}
              >
                <CircleStopIcon className="w-4 h-4 mr-2" />
                Stop
              </ControlButton>
              <ControlButton
                disabled={
                  !server.status || server.status !== 'running' || server.locked
                }
                path={`/server/${service.id}/restart`}
              >
                <ListRestartIcon className="w-4 h-4 mr-2" />
                Restart
              </ControlButton>
              <ControlButton
                disabled={
                  !server.status || server.status !== 'running' || server.locked
                }
                path={`/server/${service.id}/shutdown`}
              >
                <PowerOffIcon className="w-4 h-4 mr-2" />
                Shutdown
              </ControlButton>
            </div>
            <div className="flex items-center gap-2 mt-4">
              <VncButton service={service} server={server} />
              <ReinstallButton service={service} server={server} />
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

const ControlButton = ({ disabled, path, children }) => {
  const [loading, setLoading] = useState(false)

  const handleAction = async () => {
    setLoading(true)
    try {
      const response = await api({
        method: 'POST',
        url: path,
      })
      if (response.result !== 'success') {
        toast({
          title: 'Error',
          description: response.message,
          variant: 'destructive',
        })
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Action failed',
        variant: 'destructive',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button
      variant="outline"
      size="sm"
      className={`${disabled && 'bg-gray-300'}`}
      onClick={handleAction}
      disabled={disabled || loading}
      loading={loading}
    >
      {loading ? 'Processing...' : children}
    </Button>
  )
}

const VncButton = ({ service, server }) => {
  const [loading, setLoading] = useState(false)
  const disabled =
    !server.status || server.status !== 'running' || loading || server.locked

  const handleVnc = async () => {
    // Open a blank window first
    const newWindow = window.open('', '', 'width=800,height=600')

    // Check if the new window was opened successfully
    if (newWindow && newWindow.document) {
      // Add a loading spinner or text to the new window
      newWindow.document.write(
        '<!DOCTYPE html><html><head><style> html, body, .container { height:100%; } .container { display: flex; justify-content: center; align-items: center; } .lds-ripple { position: relative; width: 80px; height: 80px; } .lds-ripple div { position: absolute; border: 4px solid red; opacity: 1; border-radius: 50%; animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; } .lds-ripple div:nth-child(2) { animation-delay: -0.5s; } @keyframes lds-ripple { 0% { top: 36px; left: 36px; width: 0; height: 0; opacity: 0; } 4.9% { top: 36px; left: 36px; width: 0; height: 0; opacity: 0; } 5% { top: 36px; left: 36px; width: 0; height: 0; opacity: 1; } 100% { top: 0px; left: 0px; width: 72px; height: 72px; opacity: 0; } } </style></head><body><div class="container"><div class="lds-ripple"><div></div><div></div></div></div></body></html>',
      )
    }

    if (loading) return
    setLoading(true)
    try {
      const response = await api({
        method: 'POST',
        url: `/server/${service.id}/vnc`,
      })
      if (response.result !== 'success') {
        toast({
          title: 'Error',
          description: response.message,
          variant: 'destructive',
        })
      } else {
        const { url } = response
        newWindow.location = url
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'VNC failed',
        variant: 'destructive',
      })
      if (newWindow) newWindow.close()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button
      variant="outline"
      size="sm"
      className={`${disabled && 'bg-gray-300'}`}
      onClick={handleVnc}
      disabled={disabled}
      loading={loading}
    >
      <MonitorIcon className="w-4 h-4 mr-2" />
      VNC
    </Button>
  )
}

const ReinstallButton = ({ service, server }) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [operatingSystems, setOperatingSystems] = useState([])
  const [selectedOs, setSelectedOs] = useState(null)
  const [rootPassword, setRootPassword] = useState('')
  const [showRootPassword, setShowRootPassword] = useState(false)

  useEffect(() => {
    const fetchOperatingSystems = async () => {
      try {
        const response = await api({
          method: 'GET',
          url: '/server/ostemplates',
        })
        setOperatingSystems(response.osTemplates)
      } catch (error) {
        console.error('Failed to fetch operating systems:', error.message)
      }
    }
    fetchOperatingSystems()
  }, [])

  const handleReinstall = async () => {
    if (loading) return
    setLoading(true)
    try {
      if (!selectedOs) {
        return toast({
          title: 'Error',
          description: 'Please select an operating system',
          variant: 'destructive',
        })
      }
      const rootPassword = generateRootPassword()
      setRootPassword(rootPassword)
      const response = await api({
        method: 'POST',
        url: `/server/${service.id}/rebuild`,
        data: {
          osid: selectedOs,
          password: rootPassword,
          password_confirm: rootPassword,
        },
      })
      if (response.result !== 'success') {
        toast({
          title: 'Error',
          description: response.message,
          variant: 'destructive',
        })
      } else {
        setShowRootPassword(true)
        toast({
          title: 'Success',
          description: 'Server is being reinstalled',
        })
      }
    } catch (error) {
      console.error('Reinstall failed:', error.message)
      toast({
        title: 'Error',
        description: 'Reinstall failed',
        variant: 'destructive',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          disabled={loading || !server.status || server.locked}
        >
          <RefreshCwIcon className="w-4 h-4 mr-2" />
          Reinstall
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Reinstall Server</AlertDialogTitle>
          <AlertDialogDescription>
            {!showRootPassword && (
              <Select onValueChange={(value) => setSelectedOs(value)}>
                <SelectTrigger>
                  <SelectValue placeholder="Select Operating System" />
                </SelectTrigger>
                <SelectContent>
                  {operatingSystems.map((template) => (
                    <SelectItem key={template.osid} value={template.osid}>
                      {template.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
            {showRootPassword && (
              <div className="flex flex-col gap-2">
                <p>Root Password:</p>
                <CopyBlock value={rootPassword} />
              </div>
            )}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          {!showRootPassword && (
            <Button
              onClick={handleReinstall}
              disabled={loading}
              loading={loading}
            >
              Submit
            </Button>
          )}
          <AlertDialogCancel>Close</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default ServerControls
