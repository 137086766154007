import React from 'react'
import Sidebar from './Sidebar'
import Topbar from './Topbar'
import { Helmet } from 'react-helmet'

const DashboardLayout = ({ page, content, crumbs }) => {
  return (
    <div className="grid min-h-screen w-full grid-cols-[280px_1fr]">
      <Helmet>
        <title>{page} - PureGPU</title>
      </Helmet>
      <Sidebar page={page} />
      <div className="flex flex-col bg-muted/40 justify-center items-center">
        <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 max-w-screen-lg w-full m-auto">
          <Topbar crumbs={crumbs} />
          {content}
        </main>
      </div>
    </div>
  )
}

export default DashboardLayout
