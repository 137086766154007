import {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from 'react'
import api from '../api/api'

// Create a context for authentication state
const AuthContext = createContext()

// Custom hook to provide authentication state and updater function
export const useAuth = () => {
  return useContext(AuthContext)
}

// Provider component to wrap the application
export const AuthProvider = ({ children }) => {
  const [isAuthed, setIsAuthed] = useState(null)

  const fetchAuthStatus = useCallback(() => {
    try {
      api(
        {
          method: 'get',
          url: '/auth/check',
        },
        (data) => {
          setIsAuthed(data.result !== 'unauthenticated')
        },
        true,
      )
    } catch (error) {
      setIsAuthed(null)
    }
  }, [])

  useEffect(() => {
    fetchAuthStatus()
  }, [fetchAuthStatus])

  const updateAuth = async () => {
    await fetchAuthStatus()
  }

  return (
    <AuthContext.Provider value={{ isAuthed, updateAuth }}>
      {children}
    </AuthContext.Provider>
  )
}
