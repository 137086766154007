import { createClient } from 'contentful'

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
})

export const getStaticContent = async () => {
  const entries = await client.getEntries({
    content_type: 'staticContent',
    limit: 1,
  })
  return entries.items[0]
}

export const listGPUs = async () => {
  const entries = await client.getEntries({
    content_type: 'gpuContent',
  })
  return entries.items
}

export const listAnnouncements = async () => {
  const entries = await client.getEntries({
    content_type: 'panelAnnouncementSlide',
  })
  return entries.items
}

export const getGPU = async (gpuName) => {
  const entries = await client.getEntries({
    content_type: 'gpuContent',
    'fields.gpuName': gpuName,
  })
  return entries.items[0]
}

export default client
