import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
} from '../../components/ui/card'
import { toast } from '../../components/ui/use-toast'

const AccountBalance = () => {
  const [balance, setBalance] = useState()

  useEffect(() => {
    api(
      {
        method: 'GET',
        url: '/billing/balance',
      },
      (data) => {
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setBalance(data.balance)
      },
    )
  }, [])

  return (
    <Card className="w-full sm:w-1/4">
      <CardHeader>
        <CardDescription>Account Balance</CardDescription>
        <CardTitle className="text-4xl">
          ${typeof balance === 'number' && balance.toFixed(2)}
        </CardTitle>
      </CardHeader>
    </Card>
  )
}

export default AccountBalance
