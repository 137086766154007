import { RegisterForm } from '../../pages/Register/Register'
import { useForm } from 'react-hook-form'
import api from '../../api/api'
import { toast } from '../../components/ui/use-toast'
import { Button } from '../../components/ui/button'
import { useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { LoginForm } from '../../pages/Login/Login'

const LoginOptions = () => {
  const [mode, setMode] = useState('register')

  return mode === 'register' ? (
    <RegisterMode setMode={setMode} />
  ) : (
    <LoginMode setMode={setMode} />
  )
}

const LoginMode = ({ setMode }) => {
  const form = useForm()
  const { updateAuth } = useAuth()

  const submitLogin = async (data) => {
    await api(
      {
        method: 'POST',
        url: '/auth/login',
        data: data,
      },
      (response) => {
        if (response.result !== 'success') {
          return toast({
            title: 'Error',
            description: response.message || 'An error occurred',
            variant: 'destructive',
          })
        }

        toast({
          title: 'Success',
          description: response.message,
        })

        updateAuth()
      },
    )
  }

  return (
    <>
      <Button
        className="mb-4 p-0 text-md"
        variant="link"
        onClick={() => setMode('register')}
      >
        Dont have an account? Click here to register.
      </Button>

      <LoginForm onSubmit={submitLogin} form={form} />
    </>
  )
}

const RegisterMode = ({ setMode }) => {
  const form = useForm()
  const { updateAuth } = useAuth()

  const submitRegister = async (data) => {
    await api(
      {
        method: 'POST',
        url: '/auth/register',
        data: data,
      },
      (response) => {
        if (response.result !== 'success') {
          return toast({
            title: 'Error',
            description: response.message || 'An error occurred',
            variant: 'destructive',
          })
        }

        toast({
          title: 'Success',
          description: response.message,
        })

        updateAuth()
      },
    )
  }

  return (
    <>
      <Button
        className="mb-4 p-0 text-md"
        variant="link"
        onClick={() => setMode('login')}
      >
        Already have an account? Click here to login.
      </Button>

      <RegisterForm onSubmit={submitRegister} form={form} />
    </>
  )
}

export default LoginOptions
