import DashboardLayout from '../../components/DashboardLayout'
import React from 'react'
import CreditCards from './CreditCards'
import AccountBalance from './Balance'
import TopUp from './TopUp'
import Invoices from './Invoices'

const Billing = () => {
  return (
    <DashboardLayout
      page={'Billing'}
      crumbs={[
        { text: 'Dashboard', href: '/home' },
        { text: 'Billing', href: '/billing' },
      ]}
      content={
        <>
          <div className="flex flex-col sm:flex-row gap-4">
            <AccountBalance />
            <TopUp />
          </div>
          <CreditCards />
          <Invoices />
        </>
      }
    />
  )
}

export default Billing
