import DashboardLayout from '../../components/DashboardLayout'
import { Link, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import CardPaymentButton from './CardPaymentButton'
import CryptoPaymentButton from './CryptoPaymentButton'
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription,
} from '../../components/ui/card'
import { Badge } from '../../components/ui/badge'
import moment from 'moment'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from '../../components/ui/table'
import { toast } from '../../components/ui/use-toast'

const BillingInvoice = () => {
  const { id } = useParams()
  const [invoice, setInvoice] = useState(null)

  const fetchInvoice = async () => {
    await api(
      {
        method: 'GET',
        url: `/billing/invoices/${id}`,
      },
      (data) => {
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setInvoice(data.invoice)
      },
    )
  }

  useEffect(() => {
    fetchInvoice()
  }, [id])

  return (
    <DashboardLayout
      page={'Billing'}
      crumbs={[
        { text: 'Dashboard', href: '/home' },
        { text: 'Billing', href: '/billing' },
        { text: 'Invoice #' + id, href: '/billing/invoice/' + id },
      ]}
      content={
        invoice && (
          <Card>
            <CardHeader>
              <div className="flex justify-between items-center">
                <CardTitle>Invoice #{id}</CardTitle>
                <InvoiceStatus
                  invoice={invoice}
                  id={id}
                  fetchInvoice={fetchInvoice}
                />
              </div>
            </CardHeader>
            <CardContent>
              <div>
                <span className="font-bold">Due Date:</span>{' '}
                {moment(invoice.duedate).format('MM/DD/YY')}
              </div>
              <div>
                <span className="font-bold">Invoice Date:</span>{' '}
                {moment(invoice.date).format('MM/DD/YY')}
              </div>

              <InvoiceTable invoice={invoice} />
              <TransactionTable transactions={invoice?.transactions} />
            </CardContent>
          </Card>
        )
      }
    />
  )
}

const InvoiceStatus = ({ invoice, id, fetchInvoice }) => {
  let badgeColor = invoice.status === 'Paid' ? 'bg-green-500' : 'bg-red-500'

  return (
    <div>
      {invoice.status === 'Unpaid' && (
        <>
          <CardPaymentButton
            invoice={invoice}
            id={id}
            fetchInvoice={fetchInvoice}
          />
          <CryptoPaymentButton
            invoice={invoice}
            id={id}
            fetchInvoice={fetchInvoice}
          />
        </>
      )}

      <Badge className={badgeColor + ' text-base'}>
        {invoice.status.toUpperCase()}
        {invoice.status === 'Paid' &&
          ' - ' + moment(invoice.datepaid).format('MM/DD/YY')}
      </Badge>
    </div>
  )
}

const InvoiceTable = ({ invoice }) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Description</TableHead>
          <TableHead className="text-right">Amount</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {invoice.items.map((item) => (
          <TableRow key={item.id}>
            <TableCell>{item.description}</TableCell>
            <TableCell className="text-right">${item.amount}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell className="text-right">Sub Total</TableCell>
          <TableCell className="text-right">${invoice.subtotal}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="text-right">Credit</TableCell>
          <TableCell className="text-right">${invoice.credit}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="text-right">Total</TableCell>
          <TableCell className="text-right">${invoice.total}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

const TransactionTable = ({ transactions }) => {
  if (!transactions || transactions.length === 0) return null

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>ID</TableHead>
          <TableHead>Date</TableHead>
          <TableHead>Description</TableHead>
          <TableHead>Amount</TableHead>
          <TableHead>Gateway</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {transactions.map((transaction) => (
          <TableRow key={transaction.id}>
            <TableCell>{transaction.id}</TableCell>
            <TableCell>{transaction.date}</TableCell>
            <TableCell>{transaction.description}</TableCell>
            <TableCell>
              ${transaction.amountin - transaction.amountout}
            </TableCell>
            <TableCell>{transaction.gateway}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default BillingInvoice
