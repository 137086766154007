import axios from 'axios'
import { wrapper } from 'axios-cookiejar-support'
import { CookieJar } from 'tough-cookie'

wrapper(axios)

const cookieJar = new CookieJar()

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  jar: cookieJar,
  withCredentials: true,
})

// Add a response interceptor to prevent throwing errors on 401 responses
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle 401 errors
      return Promise.resolve({ data: { result: 'unauthenticated' } })
    }
    return Promise.reject(error)
  },
)

const api = async ({ ...params }, cb, redirectBypass) => {
  const response = await instance({ ...params })
  if (!redirectBypass && response.data.result === 'unauthenticated') {
    window.location.href = '/login'
    return { result: 'unauthenticated' }
  }
  if (cb) cb(response.data)
  else return response.data
}

export const isAuthed = (cb) => {
  api(
    {
      method: 'get',
      url: '/auth/check',
    },
    (data) => {
      if (data.result === 'unauthenticated') {
        cb(false)
      } else {
        cb(true)
      }
    },
    true,
  )
}

export default api
