import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Input } from '../../components/ui/input'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components/ui/form'
import OSSelect from './OSSelect'
import SSHKeySelect from './SSHKeySelect'
import { useAuth } from '../../hooks/useAuth'

const ProductOptions = ({ options, setOptions }) => {
  const form = useForm()
  const fields = form.watch()
  const { isAuthed } = useAuth()

  useEffect(() => {
    setOptions({ ...options, ...fields })
  }, [fields])

  return (
    <Form {...form}>
      <form>
        <FormField
          control={form.control}
          name="hostname"
          render={({ field }) => (
            <FormItem className="mb-2">
              <FormLabel>Hostname</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Hostname" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="os"
          render={({ field }) => (
            <FormItem className="mb-2">
              <FormLabel>Operating System</FormLabel>
              <FormControl>
                <OSSelect {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {isAuthed && (
          <FormField
            control={form.control}
            name="sshkey"
            render={({ field }) => (
              <FormItem className="mb-2">
                <FormLabel>SSH Key</FormLabel>
                <FormControl>
                  <SSHKeySelect {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
      </form>
    </Form>
  )
}

export default ProductOptions
