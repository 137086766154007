import React from 'react'
import api from '../../api/api'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../../components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../../components/ui/card'
import { Input } from '../../components/ui/input'
import { Label } from '../../components/ui/label'
import { toast } from '../../components/ui/use-toast'

const ForgotPassword = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()

  const onSubmit = async (data) => {
    await api(
      {
        method: 'POST',
        url: '/auth/reset-password',
        data: data,
      },
      (response) => {
        if (response.result !== 'success') {
          return toast({
            title: 'Error',
            description: response.message || 'An error occurred',
            variant: 'destructive',
          })
        }

        toast({
          title: 'Success',
          description: response.message,
        })

        navigate('/login')
      },
    )
  }

  return (
    <form
      className="flex items-center justify-center h-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col items-center justify-center gap-8 w-fit">
        <img src="/images/logo.svg" alt="logo" className="h-10 w-auto" />
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <CardTitle className="text-2xl">Forgot Password</CardTitle>
            <CardDescription>
              Enter your email below to reset your password
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  {...register('email', { required: true })}
                  required
                />
                {errors.email && (
                  <span className="text-xs text-red-600">
                    {errors.email.message || 'This field is required'}
                  </span>
                )}
              </div>
              <Button type="submit" className="w-full" loading={isSubmitting}>
                Reset Password
              </Button>
            </div>
            <div className="mt-4 text-center text-sm">
              Remember your password?{' '}
              <Link to="/login" className="underline">
                Login
              </Link>
            </div>
          </CardContent>
        </Card>
      </div>
    </form>
  )
}

export default ForgotPassword
