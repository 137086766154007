import DashboardLayout from '../../components/DashboardLayout'
import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { Link } from 'react-router-dom'
import ViewTable from '../../components/ViewTable'
import { Button } from '../../components/ui/button'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '../../components/ui/card'
import { toast } from '../../components/ui/use-toast'
import { Checkbox } from '../../components/ui/checkbox'

const ServiceList = () => {
  const [services, setServices] = useState([])
  const [loading, setLoading] = useState(true)
  const [showInactive, setShowInactive] = useState(true)

  useEffect(() => {
    setLoading(true)
    api(
      {
        method: 'GET',
        url: '/services',
      },
      (data) => {
        setLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setServices(
          data.services.sort((a, b) => {
            return b.id - a.id
          }),
        )
      },
    )
  }, [])

  const columns = [
    {
      accessorKey: 'domain',
      header: 'Hostname',
    },
    {
      accessorKey: 'dedicatedip',
      header: 'IP',
    },
    {
      accessorFn: (row) =>
        `$${row.billingamount} ${row.billingcycle === 'hourly' ? '/hr' : '/mo'}`,
      header: 'Price',
    },
    {
      accessorKey: 'status',
      header: 'Status',
    },
    {
      accessorKey: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <Link to={`/service/${row.original.id}`}>
          <Button>Manage</Button>
        </Link>
      ),
    },
  ]

  let displayServices = services.filter((service) => {
    if (showInactive) return true
    else {
      if (
        service.status === 'Terminated' ||
        service.status === 'Suspended' ||
        service.status === 'Cancelled'
      )
        return false
      else return true
    }
  })

  return (
    <DashboardLayout
      page={'Services'}
      crumbs={[
        { text: 'Dashboard', href: '/home' },
        { text: 'Services', href: '/services' },
      ]}
      content={
        <Card>
          <CardHeader className="pb-3">
            <CardTitle>Services</CardTitle>
            <CardDescription>Manage your services</CardDescription>
          </CardHeader>
          <CardContent className="pt-3">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="showInactive"
                checked={showInactive}
                onClick={() => setShowInactive(!showInactive)}
              />
              <label htmlFor="showInactive" className="text-sm font-medium">
                Show inactive services
              </label>
            </div>
            <ViewTable
              columns={columns}
              data={displayServices}
              loading={loading}
            />
          </CardContent>
        </Card>
      }
    />
  )
}

export default ServiceList
