import React from 'react'
import DashboardLayout from '../../components/DashboardLayout'
import RecentTickets from './RecentTickets'
import UnpaidInvoices from './UnpaidInvoices'
import Announcements from './Announcements'

const Home = () => {
  return (
    <DashboardLayout
      page={'Home'}
      crumbs={[
        { text: 'Dashboard', href: '/home' },
        { text: 'Home', href: '/home' },
      ]}
      content={
        <div className="flex flex-col gap-4">
          <Announcements />
          <div className="flex flex-row gap-4 w-full">
            <RecentTickets />
            <UnpaidInvoices />
          </div>
        </div>
      }
    />
  )
}

export default Home
